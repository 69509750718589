.quote {
    font-style: italic;
    font-size: 20px;
}

.quote span {
    margin: 10px;
    font-family: Georgia, serif;
    font-size: 80px;
    line-height: 1;
}