html {
    height: 100%;
}

body {
    height: 100%;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #d9ecb9;
    color: #609e68;
}

#root {
    height: 100%;
}

.bg-light {
    background-color: transparent !important;
}

.nav-link {
    color: #609e68 !important;
}