.frame {
    border-radius: 50%;
    border: solid 5px #cce08c;
    width: 300px;
    height: 300px;
}

.frame-out {
    border-radius: 50%;
    border: solid 5px #609e68;
    width: 320px;
    height: 310px;
    margin: 10px;
}

.carousel-item img {
    object-fit: cover;
    height: 300px;
}